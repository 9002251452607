:root {
  /* Colors: */
  --light-red: #bc0c32;
  --light-green: #b2cc34;
  --light-blue: #009dd9;
  --highlight-blue: #e4f4fa;
  --medium-red: #97002e;
  --medium-grey: #8c8f93;
  --medium-blue: #0066b2;
  --dark-grey: #6b6d6f;
  --dark-blue: #0b2d71;
  --light-grey: #dbdcdd;
  --warning-bg-color: #ffe399;
  --warning-border: 1px solid #ceb614;
  --warning-text-color: #212121;
  --success-bg-color: #c5e1a5;
  --success-border: 1px solid #ded;
  --success-text-color: #212121;
  --info-bg-color: #bef;
  --info-border: 1px solid #ddd;
  --info-text-color: #212121;
  --error-bg-color: #ffbaba;
  --error-border: 1px solid #edd;
  --error-text-color: #212121;
  --transparent-bg-color: transparent;

  /* Font/text values */
  --unnamed-font-family-gotham-narrow: Gotham Narrow;
  --unnamed-font-family-gotham: Gotham;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-size-10: 10px;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-32: 32px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-0-01: 0.01px;
  --unnamed-character-spacing-0-02: 0.02px;
  --unnamed-line-spacing-14: 14px;
  --unnamed-line-spacing-18: 18px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-30: 30px;

  --app-normal-text-size: 14px;
  --app-heading1-text-size: 20px;
  --app-heading2-text-size: 18px;
  --app-heading3-text-size: 16px;
}

/* Character Styles */
.body-2-14pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--app-normal-text-size);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}

.caption-12pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-18);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.overline-10pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-10);
  line-height: var(--unnamed-line-spacing-14);
  letter-spacing: var(--unnamed-character-spacing-0-01);
  color: var(--unnamed-color-000000);
}

.subtitle-2-14pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.subtitle-2-16pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.h4-headline-20pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.h6-headline-narrow-—-18pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.button-14pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0-02);
  color: var(--unnamed-color-000000);
}

.body-2-14pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.subtitle-3-14pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.h6-headline-narrrrow-—-18pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}

.subtitle-4-14pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-blue-0b2d71);
}

.subtitle-3-14pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-blue-0b2d71);
}

.h4-headline-20pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-blue-0b2d71);
}

.h2-headline-32pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-32);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0-01);
  color: var(--dark-blue-0b2d71);
}

.h3-headline-24pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-blue-0b2d71);
}

.subtitle-2-—-16pt {
  font-family: var(--unnamed-font-family-gotham-narrow);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-blue-0b2d71);
}

.subtitle-1-16pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-blue-0b2d71);
}

.subtitle-1-16pt {
  font-family: var(--unnamed-font-family-gotham);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}
